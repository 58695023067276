import React from 'react';
import styles from './LandingPage.module.scss';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className={styles.landingPage}>
      <h1>
        <span> Секој сон </span>нуди своја приказна
      </h1>
      <p>
        Нарачајте душек со вашите посакувани димензии што е изработен со висок
        квалитет и според најновите сертификати.
      </p>
      <div className={styles.buttonDiv}>
       <Link className={styles.button} to='/contact'>Контакт</Link> 
       </div>
    </div>
  );
};

export default LandingPage;
