import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Drawer.style.scss';

import MenuIcon from '../../images/menu.svg';
import CloseIcon from '../../images/close-icon.svg';
import FbIcon from '../../images/fb-menu.svg';

const Drawer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <img src={MenuIcon} onClick={handleShow} className="btn" alt="" />
      <Offcanvas show={show} onHide={handleClose} id="main" placement="end">
        <img src={CloseIcon} onClick={handleClose} className="close" alt="" />
        <Link className="links" onClick={handleClose} to="/">
          Почетна
        </Link>
        <Link className="links" onClick={handleClose} to="/products">
          Душеци
        </Link>
        <Link className="links" onClick={handleClose} to="/about">
          За нас
        </Link>
        <Link className="links" onClick={handleClose} to="/contact">
          Контакт
        </Link>
        <div className="middleLine"></div>

        <img
          src={FbIcon}
          alt=""
          onClick={() => window.open('https://facebook.com/DusheciSpanko')}
          className="icon"
        />
      </Offcanvas>
    </>
  );
};

export default Drawer;
