import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Drawer from '../Drawer/Drawer';

import styles from './Header.module.scss';

import LogoIcon from '../../images/Logo.svg';

const Header = () => {
  return (
    <Navbar className={`w-100 ${styles.navbar}`}>
      <Container>
        <Navbar.Brand className={styles.brand}>
          <img src={LogoIcon} alt="" />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`${styles.nav}`}>
            <Link to="/" className={styles.links}>
              Почетна
            </Link>
            <Link to="/about" className={styles.links}>
            За нас
            </Link>
            <Link to="/products" className={styles.links}>
              Продукти
            </Link>
            <Link to="/contact" className={styles.links}>
              Контакт
            </Link>
          </Nav>
        </Navbar.Collapse>
        <span className={styles.span}>
          {' '}
          <Drawer />
        </span>
      </Container>
    </Navbar>
  );
};

export default Header;
