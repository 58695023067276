import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./Loader.module.scss";


const Loader = () => {
    return (
        <div className={styles.loader}>
        <Spinner className={styles.spinner} animation="grow" />
        </div>
    )
}

export default Loader;