import React, { Suspense } from 'react';
import { Homepage } from './pages/Homepage';
import { Header } from './components/Header';
import styles from './App.module.scss';
import { Routes, Route } from 'react-router-dom';
import Loader from './components/Loader/Loader';
const About = React.lazy(() => import('./pages/About/About'))
const Contact = React.lazy(() => import('./pages/Contact/Contact')) ;
const List = React.lazy(() => import('./pages/Products/List/List')) ;
const Kids = React.lazy(() => import('./pages/Products/Product/Kids/Product'))
const Relax = React.lazy(() => import('./pages/Products/Product/Relax/Product')) ;
const Magic = React.lazy(() => import('./pages/Products/Product/Magic/Product')) ;
const ErrorPage = React.lazy(() => import('./pages/ErrorPage/ErrorPage'));
const Footer = React.lazy(() => import('./components/Footer/Footer')) ;

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
    <div className={`${styles.app}`}>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<List />} />
        <Route path="/products/1" element={<Relax id={'1'} />} />
        <Route path="/products/2" element={<Kids id={'2'} />} />
        <Route path="/products/3" element={<Magic id={'3'} />} />
        <Route path="*" element={<ErrorPage />} />  
      </Routes>
      <Footer />
    </div>
    </Suspense>
  );
};

export default App;
